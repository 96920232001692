<template>
  <div class="quill">
    <div :id="toolbarId">
      <div class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-link"></button>
        <button class="ql-blockquote"></button>
        <button class="ql-code"></button>
        <button class="ql-image"></button>
        <button type="button" class="ql-list" value="ordered"></button>
        <button type="button" class="ql-list" value="bullet"></button>
      </div>
    </div>
    <div :id="editorId" :name="name" ref="editor" data-cy="quill-html-editor" class="#quill-editor"></div>
  </div>
</template>
<script>
import "quill/dist/quill.snow.css";
export default {
  name: "html-editor",
  props: {
    value: {
      type: String,
      default: "",
    },
    name: String,
    placeholder: String,
  },
  data() {
    return {
      editor: null,
      content: null,
      lastHtmlValue: "",
      editorId: null,
      toolbarId: null,
    };
  },
  methods: {
    initialize(Quill) {
      this.editor = new Quill(`#${this.editorId}`, {
        theme: "snow",
        modules: {
          toolbar: `#${this.toolbarId}`,
        },
      });
      if (this.value.length > 0) {
        this.editor.pasteHTML(this.value);
      }
      let editorRef = this.$refs.editor;
      let node = editorRef.children[0];
      this.editor.on("text-change", () => {
        let html = node.innerHTML;
        if (html === "<p><br></p>") {
          html = "";
        }
        this.content = html;
        this.$emit("input", this.content);
      });
    },
    pasteHTML() {
      if (!this.editor) {
        return;
      }
      this.editor.pasteHTML(this.value);
    },
    randomString() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  async mounted() {
    let Quill = await import("quill");
    Quill = Quill.default || Quill;
    this.editorId = this.randomString();
    this.toolbarId = this.randomString();
    this.$nextTick(() => {
      this.initialize(Quill);
    });
  },
  watch: {
    value(newVal) {
      if (newVal !== this.content) {
        this.pasteHTML(newVal);
      }
    },
  },
};
</script>
<style lang="scss">
.quill {
  .ql-toolbar.ql-snow {
    border-radius: 0.7125rem 0.7125rem 0 0;
    border: 2px solid rgba(15, 136, 242, 0.3);
  }
  .ql-container.ql-snow {
    border-radius: 0 0 0.7125rem 0.7125rem;
    border: 2px solid rgba(15, 136, 242, 0.3);
    min-height: 10em;
    &:hover {
      cursor: text;
    }
    @media screen and (min-width: 768px) {
      min-height: 20em;
    }
  }
  &:focus-within {
    .ql-toolbar.ql-snow {
      border: 2px solid rgb(15, 136, 242);
      border-bottom: none;
    }
    .ql-container.ql-snow {
      border: 2px solid rgb(15, 136, 242);
    }
  }
}

.ql-snow .ql-fill {
  fill: #ccc;
}
.ql-snow .ql-stroke {
  stroke: #ccc;
}
button[type="button"] {
  &:hover {
    cursor: pointer;
  }
}
</style>
